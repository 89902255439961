import HFSwitch from 'common/formControls/switches/HFSwitch'
import { HFRichTextEditLite } from 'common/formControls/textInputs/HFRichTextEdit'
import HFTextField from 'common/formControls/textInputs/HFTextField'
import PropTypes from 'prop-types'
import { useWatch } from 'react-hook-form'

const Cell = ({ itemIdx }) => {
  const { displayText } = useWatch({ name: `items.${itemIdx}` })

  return (
    <td>
      <HFSwitch
        label="Display only?"
        margin="dense"
        name={`items.${itemIdx}.displayText`}
      />
      {displayText ? (
        <HFRichTextEditLite
          features={{ 'images.inline': true }}
          label="Cell Contents"
          minimized
          name={`items.${itemIdx}.value`}
        />
      ) : (
        <HFTextField
          label="Initial Cell Contents"
          name={`items.${itemIdx}.value`}
        />
      )}
    </td>
  )
}
Cell.propTypes = {
  itemIdx: PropTypes.number.isRequired,
}

export default Cell
