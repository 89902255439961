import DeleteIcon from '@mui/icons-material/DeleteForever'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import type { itemRendererProps } from 'common/formControls/lists/AdvancedList'
import SwitchFormControl from 'common/formControls/switches/SwitchFormControl'
import TextField from 'common/formControls/textInputs/TextField'
import Well from 'common/layout/Well'
import Headline from 'common/text/Headline'
import { set } from 'fp/objects'
import { type SyntheticEvent, forwardRef } from 'react'

interface ItemRendererProps {
  allowDeletion?: boolean
  disabled?: boolean
  index: number
  onChange: (value: unknown) => void
  newOption: () => void
  removeItem: (index: number) => void
  value: {
    [key: string]: string | boolean | { id: string; label: string }[]
    name: string
    useEachOptionOnlyOnce: boolean
    options: DropdownOption[]
  }
}

const ItemRenderer = forwardRef(
  (props: ItemRendererProps & typeof itemRendererProps, _ref) => {
    const {
      allowDeletion,
      disabled,
      index,
      onChange,
      newOption,
      removeItem,
      value,
    } = props
    const handleValueChange =
      (fieldName: string) => (event: SyntheticEvent) => {
        const targetValue = (event.target as HTMLInputElement).value
        onChange(set(fieldName, targetValue)(value))
      }

    const handleRemove = () => removeItem(index)

    const handleChange = (fieldName: string) => () =>
      onChange(set(fieldName, !value[fieldName])(value))
    const optionNumber = index + 1

    return (
      <>
        <Well
          component="fieldset"
          sxProps={{ flex: 1 }}>
          <Box component="legend">
            <Headline title={`Dropdown Options ${optionNumber}`} />
          </Box>
          <Box mb={2}>
            <TextField
              label="Name"
              name={`name-${optionNumber}`}
              onChange={handleValueChange('name')}
              value={value.name}
              required
              variant="filled"
            />
          </Box>
          <Box mb={2}>
            <SwitchFormControl
              checked={value.useEachOptionOnlyOnce}
              label="Use each option only once"
              helperText="Options will be removed as they are used"
              onChange={handleChange('useEachOptionOnlyOnce')}
              value={value.useEachOptionOnlyOnce}
            />
          </Box>
          <Box mb={2}>
            {value.options.map((item, index) => (
              <Box
                key={item.id}
                display="flex">
                <TextField
                  label={`Option-${index + 1}`}
                  required
                  onChange={({ target }) => {
                    const updatedOptions = value.options.map((option, i) =>
                      i === index ? { ...option, label: target.value } : option,
                    )
                    onChange(set('options', updatedOptions)(value))
                  }}
                  name={`option-${index + 1}`}
                  value={item.label}
                  variant="filled"
                />
                <IconButton
                  disabled={disabled}
                  onClick={() => {
                    const updatedOptions = value.options.filter(
                      (_option, i) => i !== index,
                    )
                    onChange(set('options', updatedOptions)(value))
                  }}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
          </Box>
          <Button
            disabled={disabled}
            onClick={() => {
              onChange(set('options', [...value.options, newOption()])(value))
            }}>
            Add Option
          </Button>
        </Well>
        {Boolean(allowDeletion) && (
          <IconButton
            disabled={disabled}
            onClick={handleRemove}>
            <DeleteIcon />
          </IconButton>
        )}
      </>
    )
  },
)

export default ItemRenderer

export type DropdownOption = { id: string; label: string }

export type DropdownOptionSet = {
  id: string
  name: string
  useEachOptionOnlyOnce: boolean
  options: DropdownOption[] | []
}
