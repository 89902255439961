import RemoveCircle from '@mui/icons-material/RemoveCircle'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import { CHANGE_HANDLER_NUMERIC } from 'common/formControls/Form/withHookForm'
import HFTextField from 'common/formControls/textInputs/HFTextField'
import ResponsiveTable from 'common/layout/ResponsiveTable'
import { componentShape } from 'core/shapes'
import { filter, map } from 'fp/arrays'
import { sum } from 'fp/numbers'
import { get } from 'fp/objects'
import { generateId, isDefined, matches, not } from 'fp/utils'
import useToggleState from 'hooks/useToggleState'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { compose } from 'redux'
import Cell from './Cell'

const Table = ({
  allowColumnEdits = false,
  allowRowEdits = false,
  newItemProps = {
    contentType: 'text',
    isStatic: true,
    value: '',
    isRichInput: false,
  },
  CellRenderer = Cell,
}) => {
  const ref = useRef()
  const addRowButtonRef = useRef()
  const {
    append: appendColumn,
    fields: columns,
    remove: removeColumn,
  } = useFieldArray({ name: 'columns', keyName: 'key' })
  const {
    append: appendRow,
    fields: rows,
    remove: removeRow,
  } = useFieldArray({ name: 'rows', keyName: 'key' })
  const {
    append: appendItem,
    fields: items,
    replace: replaceItems,
  } = useFieldArray({ name: 'items', keyName: 'key' })
  const [removedRow, setRemovedRow] = useState()
  const [addedRow, toggleAddedRow] = useToggleState()
  const { getValues } = useFormContext()

  const handleAddRow = () => {
    const newRowId = generateId()
    toggleAddedRow()
    appendRow({ id: newRowId })
    appendItem(
      columns.map(({ id: columnId }) => ({
        ...newItemProps,
        id: generateId(),
        column: columnId,
        row: newRowId,
      })),
    )
  }

  const handleRemoveRow = idx => {
    const { id: rowId } = rows[idx]
    setRemovedRow(idx)
    compose(replaceItems, filter(compose(not, matches('row', rowId))))(items)
    removeRow(idx)
  }

  const handleAddColumn = () => {
    const newColumnId = generateId()
    appendColumn({
      id: newColumnId,
      header: '',
    })
    appendItem(
      rows.map(({ id: rowId }) => ({
        ...newItemProps,
        id: generateId(),
        column: newColumnId,
        row: rowId,
      })),
    )
  }

  const handleRemoveColumn = idx => {
    const { id: columnId } = columns[idx]
    compose(
      replaceItems,
      filter(compose(not, matches('column', columnId))),
    )(items)
    removeColumn(idx)
  }

  useEffect(() => {
    if (isDefined(removedRow)) {
      if (removedRow === rows.length || rows.length === 1) {
        addRowButtonRef.current?.focus()
      } else {
        const selector =
          removedRow === 0 ? 'first-child' : `nth-child(${removedRow + 1})`
        ref.current.querySelector(`tr:${selector} button`)?.focus()
      }
      setRemovedRow(null)
    }
  }, [removedRow, rows])

  useEffect(() => {
    if (addedRow) {
      ref.current
        .querySelector(`tr:nth-child(${rows.length}) textarea,
      tr:nth-child(${rows.length}) input[type="text"] `)
        ?.focus()
      toggleAddedRow()
    }
  }, [addedRow, rows, toggleAddedRow])

  return (
    <Stack
      gap={2}
      my={8}>
      {Boolean(allowColumnEdits) && (
        <Button
          onClick={handleAddColumn}
          size="small"
          style={{ alignSelf: 'flex-start' }}>
          Add Column
        </Button>
      )}

      <ResponsiveTable sx={{ th: { textTransform: 'none' } }}>
        <thead>
          <tr>
            {columns.map(({ id }, idx) => (
              <th key={id}>
                {Boolean(columns.length > 1 && allowColumnEdits) && (
                  <Tooltip title={`Remove Column ${idx + 1}`}>
                    <IconButton
                      data-testid={`remove-col-btn-${idx}`}
                      onClick={() => handleRemoveColumn(idx)}>
                      <RemoveCircle />
                    </IconButton>
                  </Tooltip>
                )}

                {allowColumnEdits ? (
                  <Stack>
                    <HFTextField
                      label={`Column ${idx + 1} Header`}
                      name={`columns.${idx}.header`}
                      required
                    />

                    <HFTextField
                      // Treat the Text Field like a numeric field because we only want integers
                      // along with a custom validation rule
                      changeHandlerType={CHANGE_HANDLER_NUMERIC}
                      helperText="All width values must add up to 100."
                      inputProps={{
                        min: 0,
                        max: 100,
                      }}
                      label="Column Width Percentage"
                      name={`columns.${idx}.width`}
                      rules={{
                        validate: () => {
                          const { columns: columnFields } = getValues()

                          const widthValues = compose(
                            filter(Boolean),
                            map(get('width')),
                          )(columnFields)

                          if (
                            sum(widthValues) === 100 &&
                            widthValues.length < columnFields.length
                          ) {
                            return 'Unable to calculate column width. Either provide a width value to all columns or reduce current values to be less than 100.'
                          }

                          // Not provided a value for all column widths is ok.
                          // We calculate a default value for these columns in this case.
                          return (
                            sum(widthValues) === 100 ||
                            widthValues.length < columnFields.length ||
                            'Column width fields must add up to 100.'
                          )
                        },
                      }}
                      step={1}
                    />
                  </Stack>
                ) : (
                  columns[idx].header
                )}
              </th>
            ))}
            {Boolean(allowRowEdits) && (
              <th
                aria-label="Remove Row"
                className="remove"
              />
            )}
          </tr>
        </thead>

        <tbody ref={ref}>
          {rows.map(({ id: rowId }, rowIdx) => (
            <tr key={rowId}>
              {columns.map(({ header, id: columnId }) => (
                <CellRenderer
                  columnHeader={header}
                  columnId={columnId}
                  itemIdx={items.findIndex(
                    item => item.column === columnId && item.row === rowId,
                  )}
                  key={`${rowId}-${columnId}`}
                  rowIdx={rowIdx}
                />
              ))}
              {Boolean(allowRowEdits) && (
                <td className="remove">
                  {rows.length > 1 && (
                    <Tooltip title={`Remove Row ${rowIdx + 1}`}>
                      <IconButton
                        onClick={() => handleRemoveRow(rowIdx)}
                        style={{ padding: 0 }}>
                        <RemoveCircle />
                      </IconButton>
                    </Tooltip>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </ResponsiveTable>
      {Boolean(allowRowEdits) && (
        <Button
          onClick={handleAddRow}
          ref={addRowButtonRef}
          size="small"
          style={{ marginTop: 20, alignSelf: 'flex-start' }}>
          Add Row
        </Button>
      )}
    </Stack>
  )
}

Table.propTypes = {
  allowColumnEdits: PropTypes.bool,
  allowRowEdits: PropTypes.bool,
  CellRenderer: componentShape,
  newItemProps: PropTypes.object,
}

export default Table
