import {
  CONTENT_STATE_DRAFT,
  CONTENT_SUB_TYPE_STANDALONE,
  CONTENT_TYPE_ECHO,
} from 'core/consts'
import { contentShape, contentTypeShape } from 'core/shapes'
import { get, omit } from 'fp/objects'
import { fallbackTo, isUndefined } from 'fp/utils'
import PropTypes from 'prop-types'
import type { ComponentType } from 'react'
import { createElement } from 'react'
import type {
  Content,
  ContentSubType,
  ContentType,
  InteractiveType,
} from 'reducers/@types/content'
import { compose } from 'redux'
import { contentBuilderUrl } from 'routing/consts'
import { labels } from './consts'

export const withEdit =
  (Component: ComponentType<{ editing: boolean }>) => () =>
    createElement(Component, { editing: true })

export const pullFormValues = ({
  content,
  contentType,
}: { content: Content; contentType: ContentType }) => ({
  assetCode: '',
  contentState: content?.contentState || CONTENT_STATE_DRAFT,
  contentType,
  ...omit('data')(content || {}),
  ...content?.data,
})

export const buildContentUrl = ({
  contentType,
  id,
}: { contentType: ContentType; id?: string }) =>
  `${contentBuilderUrl}/curriculum/${contentType}/${id}`

export const formProps = {
  content: contentShape,
  contentType: contentTypeShape.isRequired,
  editing: PropTypes.bool,
}

export const formPropsShape = PropTypes.shape(formProps)

export const getLabel = (content: {
  contentType: ContentType
  contentSubType?: ContentSubType | InteractiveType
}) => {
  if (isUndefined(content)) {
    return ''
  }

  const { contentType, contentSubType } = content

  if (
    contentType !== CONTENT_TYPE_ECHO &&
    contentSubType === CONTENT_SUB_TYPE_STANDALONE
  ) {
    return 'Single-Page Source'
  }

  return compose(
    fallbackTo(''),
    fallbackTo(get(contentType)(labels)),
    get(contentSubType),
  )(labels)
}
