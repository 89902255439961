import { createSelector, withOptions } from '@comfy/redux-selectors'
import { additionalContext } from 'common/formControls/Form/additionalContext'
import AdvancedList from 'common/formControls/lists/AdvancedList'
import { CONTENT_TYPE_INTERACTIVE, CONTENT_TYPE_SOURCE } from 'core/consts'
import { difference, filter, map } from 'fp/arrays'
import { get, pick } from 'fp/objects'
import { isEmptyString, isNotEmptyString } from 'fp/strings'
import { identity } from 'fp/utils'
import { pullLoaded } from 'projections/index'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect } from 'react'
import { useFormContext, useFormState, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import { getContentForType } from 'selectors/collapsedContent'
import { formPropsShape } from '../utils'
import ChildListItemRenderer from './ChildListItemRenderer'
import ChildListNewItem, { allowedContentTypesShape } from './ChildListNewItem'
import { availableEchoFeatures } from './Echo/utils'

const selectIdsOfEchoFeatures = withOptions(contentIds =>
  createSelector(
    getContentForType(CONTENT_TYPE_INTERACTIVE),
    compose(
      map(get('id')),
      filter(
        compose(
          contentSubType => availableEchoFeatures.includes(contentSubType),
          get('contentSubType'),
        ),
      ),
      filter(compose(id => contentIds.includes(id), get('id'))),
      map(pick('id', 'contentSubType')),
      pullLoaded,
    ),
  ),
)

const ChildList = props => {
  const {
    binaryMode,
    className,
    contentSubTypes = [],
    contentTypes,
    formProps,
    isDBI,
    name,
    tabbed = false,
  } = props

  const { disabled } = useContext(additionalContext)
  const { dirtyFields, isDirty } = useFormState()
  const formChildren = useWatch({ name: 'children' })
  const actualChildren = get('content.children')(formProps)
  const actualChildrenIds = actualChildren.map(get('id'))
  const { reset } = useFormContext()
  const idsOfEchoFeatures = useSelector(
    selectIdsOfEchoFeatures(actualChildrenIds),
  )

  useEffect(() => {
    const formChildrenIds = formChildren?.map(get('id'))

    if (
      isDirty &&
      Object.keys(dirtyFields).length === 1 &&
      dirtyFields.children &&
      difference(formChildrenIds)(actualChildrenIds)?.length
    ) {
      /**
       * It looks like the form is dirty and the only thing that changed is that
       * a new child was added to the parent content.
       *
       * This change has already been saved on the backend due to a saga that was
       * kicked off in ChildListNewItem when the new item was added.
       *
       * To reduce user confusion and to improve the workflow/experience, we will
       * now flip isDirty to false.
       */
      reset(undefined, { keepValues: true })
    }
  }, [formChildren, dirtyFields, isDirty, reset, actualChildrenIds])

  const itemRenderFilterer = useCallback(
    (_, item) => {
      /**
       * Exclude Echo features from appearing in the ChildList.  These are managed
       * by the system and the user is not allowed to interact with them directly.
       */
      if (idsOfEchoFeatures.includes(item.id)) {
        return false
      }

      // If subtypes were defined, only items that match can pass
      if (contentSubTypes.length) {
        return contentSubTypes.includes(item.contentSubType)
      }

      if (
        item.contentType === CONTENT_TYPE_SOURCE &&
        isNotEmptyString(item.contentSubType)
      ) {
        return true
      }

      // If subtypes were NOT defined, then only items without them may pass
      return isEmptyString(item.contentSubType)
    },
    [idsOfEchoFeatures, contentSubTypes],
  )

  return (
    <AdvancedList
      allowReordering={!binaryMode}
      getter={identity}
      noLabel
      {...{
        className,
        disabled,
        ItemRenderer: ChildListItemRenderer,
        itemRenderFilterer,
        name,
        ...formProps,
        tabbed,
      }}>
      {childProps => (
        <ChildListNewItem
          {...{
            ...childProps,
            binaryMode,
            contentSubTypes,
            contentTypes,
            isDBI,
            parentContent: formProps.content,
            tabbed,
          }}
        />
      )}
    </AdvancedList>
  )
}

ChildList.propTypes = {
  binaryMode: PropTypes.bool.isRequired,
  contentSubTypes: allowedContentTypesShape,
  contentTypes: allowedContentTypesShape.isRequired,
  formProps: formPropsShape.isRequired,
  isDBI: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  tabbed: PropTypes.bool,
}
export default ChildList
