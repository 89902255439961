import RichTextEdit from 'common/formControls/textInputs/HFRichTextEdit'
import { getMaxHeight } from 'common/formControls/textInputs/RichTextEdit/utils/misc'
import { arraySequence, map } from 'fp/arrays'
import { generateId } from 'fp/utils'
import withContent from 'hoc/withContent'
import SentenceFragmentInteractive from 'hss/sections/contentBlocks/interactives/SentenceFragment'
import { compose } from 'redux'
import {
  CONTENT_TYPE_INTERACTIVE,
  INTERACTIVE_TYPE_SENTENCE_FRAGMENT,
} from 'shared/consts'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'
import QuestionPrompt from '../Form/QuestionPrompt'
import Content from './Content'

const contentSubType = INTERACTIVE_TYPE_SENTENCE_FRAGMENT
const Interactive = SentenceFragmentInteractive

const newAnswerChoice = () => ({
  id: generateId(),
  label: '',
  isSelectable: true,
  isCorrect: false,
})

const defaultValues = {
  questionHtml: '',
  explanation: '',
  allowMultipleSelection: false,
  answerChoices: compose(map(newAnswerChoice), arraySequence)(4),
}

const previewButton = props => <PreviewButton {...{ Interactive, ...props }} />

const SentenceFragment = formProps => (
  <Form
    {...{ contentSubType, defaultValues, formProps, previewButton }}
    name="Interactive-SentenceFragment"
    standardsAllowed>
    <QuestionPrompt
      labelProps={{
        headline: true,
        mb: 2,
        mt: 3,
      }}
    />
    <Content newAnswerChoice={newAnswerChoice} />
    <RichTextEdit
      labelProps={{
        headline: true,
        mb: 2,
        mt: 3,
      }}
      maxHeight={getMaxHeight()}
      mb={5}
      name="explanation"
    />
  </Form>
)

const SentenceFragmentWithContent = withContent(SentenceFragment, {
  contentType: CONTENT_TYPE_INTERACTIVE,
})

export default SentenceFragmentWithContent
