import { CONTENT_TYPE_INTERACTIVE, INTERACTIVE_TYPE_POLL } from 'core/consts'
import { arraySequence, map } from 'fp/arrays'
import { generateId } from 'fp/utils'
import withContent from 'hoc/withContent'
import PollInteractive from 'hss/sections/contentBlocks/interactives/Poll'
import { compose } from 'redux'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'
import Content from './Content'

const contentSubType = INTERACTIVE_TYPE_POLL
const Interactive = PollInteractive
const newOption = () => ({ id: generateId(), label: '' })
const defaultValues = {
  prompt: '',
  options: compose(map(newOption), arraySequence)(4),
  showSelector: true,
  colsPerRow: 1,
}

const previewButton = props => <PreviewButton {...{ Interactive, ...props }} />

const Poll = formProps => (
  <Form
    {...{ contentSubType, defaultValues, formProps, previewButton }}
    name="Interactive-Poll"
    standardsAllowed>
    <Content />
  </Form>
)

const PollWithContent = withContent(Poll, {
  contentType: CONTENT_TYPE_INTERACTIVE,
})

export default PollWithContent
