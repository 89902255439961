import { arraySequence, map } from 'fp/arrays'
import { generateId } from 'fp/utils'
import withContent from 'hoc/withContent'
import FillInTheBlankAndClozeInteractive from 'hss/sections/contentBlocks/interactives/FillInTheBlankAndCloze'
import { compose } from 'redux'
import {
  CONTENT_TYPE_INTERACTIVE,
  INTERACTIVE_TYPE_FILL_IN_THE_BLANK_AND_CLOZE,
} from 'shared/consts'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'
import QuestionPrompt from '../Form/QuestionPrompt'
import Content from './Content'
import Dropdown from './DropdownOptionSets'

const contentSubType = INTERACTIVE_TYPE_FILL_IN_THE_BLANK_AND_CLOZE
const Interactive = FillInTheBlankAndClozeInteractive

const newFragment = () => ({
  id: generateId(),
  content: '',
  optionSet: '',
  type: 'readOnly',
  correctAnswer: '',
})

const newOption = () => ({ id: generateId(), label: '' })

const newDropdownSet = () => ({
  id: generateId(),
  name: '',
  options: compose(map(newOption), arraySequence)(2),
  useEachOptionOnlyOnce: false,
})

const newSentences = () => ({
  id: generateId(),
  fragments: compose(map(newFragment), arraySequence)(4),
})

const defaultValues = {
  questionHtml: '',
  explanation: '',
  dropdownOptionSets: [],
  sentences: [
    {
      id: generateId(),
      fragments: compose(map(newFragment), arraySequence)(4),
    },
  ],
}

const previewButton = props => <PreviewButton {...{ Interactive, ...props }} />

const FillInTheBlankAndCloze = formProps => (
  <Form
    {...{ contentSubType, defaultValues, formProps, previewButton }}
    name="Interactive-FillInTheBlankAndCloze"
    standardsAllowed>
    <QuestionPrompt
      labelProps={{
        headline: true,
        mb: 2,
        mt: 3,
      }}
    />
    <Dropdown
      newDropdownSet={newDropdownSet}
      newOption={newOption}
    />
    <hr />
    <Content
      newSentences={newSentences}
      newFragment={newFragment}
    />
  </Form>
)

const FillInTheBlankAndClozeWithContent = withContent(FillInTheBlankAndCloze, {
  contentType: CONTENT_TYPE_INTERACTIVE,
})

export default FillInTheBlankAndClozeWithContent
