import { Box } from '@mui/material'
import Button from '@mui/material/Button'
import AdvancedList from 'common/formControls/lists/AdvancedList'
import HFSwitch from 'common/formControls/switches/HFSwitch'
import HFNumberField from 'common/formControls/textInputs/HFNumberField'
import Headline from 'common/text/Headline'
import { identity, takeSecond } from 'fp/utils'
import { compose } from 'redux'
import { generateId } from 'shared/utils'
import QuestionPrompt from '../Form/QuestionPrompt'
import PollItemRenderer from './PollItemRenderer'

const Content = () => {
  const newAnswerChoice = () => ({ id: generateId(), label: '' })

  return (
    <>
      <QuestionPrompt name="prompt" />
      <Headline
        hr
        mt={2}
        title="Answers">
        <AdvancedList
          getter={identity}
          itemContainerProps={{
            paddingBottom: 2,
          }}
          ItemRenderer={PollItemRenderer}
          minimumItems={2}
          name="options"
          noLabel
          setter={takeSecond}>
          {({ addItem, disabled }) => (
            <Button
              disabled={disabled}
              onClick={compose(addItem, newAnswerChoice)}>
              Add answer
            </Button>
          )}
        </AdvancedList>
      </Headline>
      <HFSwitch name="allowMultipleSelection" />
      <HFSwitch
        helperText="Show radio or checkbox controls alongside text or image answers."
        name="showSelector"
      />
      <Box mt={6}>
        <HFNumberField
          label="Columns per row"
          name="colsPerRow"
        />
      </Box>
    </>
  )
}

export default Content
