import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import AdvancedList from 'common/formControls/lists/AdvancedList'
import Headline from 'common/text/Headline'
import { identity, takeSecond } from 'fp/utils'
import withProps from 'hoc/withProps'
import { compose } from 'redux'
import Item from './Item'

interface DropdownOptionSetsProps {
  newDropdownSet: () => void
  newOption: () => void
}

const DropdownOptionSets = (props: DropdownOptionSetsProps) => {
  const { newDropdownSet, newOption } = props
  const ItemRenderer = withProps(Item, { newOption })

  return (
    <Box mt={4}>
      <Headline title="Dropdown Option Sets" />
      <AdvancedList
        getter={identity}
        itemContainerProps={{
          paddingBottom: 2,
        }}
        ItemRenderer={ItemRenderer}
        name="dropdownOptionSets"
        noLabel
        setter={takeSecond}>
        {({ addItem = () => {}, disabled = false }) => (
          <Button
            disabled={disabled}
            onClick={compose(addItem, newDropdownSet)}>
            Add Dropdown Options
          </Button>
        )}
      </AdvancedList>
    </Box>
  )
}

export default DropdownOptionSets
