import HFCheckbox from 'common/formControls/switches/HFCheckbox'
import FormSection from 'common/layout/FormSection'
import HeadlineStyleOffset from 'common/text/HeadlineStyleOffset'
import useAbilityCheck from 'hooks/useAbilityCheck'
import { useSelector } from 'react-redux'
import type { Content } from 'reducers/@types/content'
import { isStructuredSource } from 'selectors/content'
import { getDecoratedUser } from 'selectors/users'
import {
  ABILITY_SHARE_AUTHORING_CONTENT_WITH_DISTRICT,
  ABILITY_SHARE_AUTHORING_CONTENT_WITH_SCHOOL,
} from 'shared/consts'

type UserState = {
  district: {
    name: string
  }
  school: {
    name: string
  }
}

type SharingControlsProps = {
  content: Content
}

const SharingControls = ({ content }: SharingControlsProps) => {
  const { district, school } = useSelector(getDecoratedUser) as UserState

  const hasShareWithDistrict = useAbilityCheck(
    ABILITY_SHARE_AUTHORING_CONTENT_WITH_DISTRICT,
  )
  const hasShareWithSchool = useAbilityCheck(
    ABILITY_SHARE_AUTHORING_CONTENT_WITH_SCHOOL,
  )

  if (!(hasShareWithDistrict || hasShareWithSchool)) return null

  if (isStructuredSource(content?.parent)) {
    return null
  }

  return (
    <HeadlineStyleOffset offset={1}>
      <FormSection
        title="Share With"
        variant="contained">
        {hasShareWithSchool && (
          <HFCheckbox
            label={`${school.name} and District Admins`}
            name="sharedWithSchool"
          />
        )}

        {hasShareWithDistrict && (
          <HFCheckbox
            label={district.name}
            name="sharedWithDistrict"
          />
        )}
      </FormSection>
    </HeadlineStyleOffset>
  )
}

// const SharingControlsWithContent = withContent(SharingControls)

export default SharingControls
