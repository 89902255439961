import PreviewIcon from '@mui/icons-material/Preview'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Popper from 'common/indicators/Popper'
import Html from 'common/text/Html'
import type { Sentence } from 'hss/ContentBuilder/interactives/FillInTheBlankAndCloze/Content'
const paperStyle = {
  minWidth: 495,
}

interface ContentProps {
  currentSentence: Sentence
}

const Content = (props: ContentProps) => {
  const { currentSentence } = props
  return (
    <Popper
      placement="top"
      paperStyle={paperStyle}
      disableCloseButton
      disablePortal={false}>
      <Popper.Control>
        <Button
          color="secondary"
          aria-label="Sentence Preview"
          startIcon={<PreviewIcon />}>
          <Typography variant="small-semibold">Sentence Preview</Typography>
        </Button>
      </Popper.Control>
      <Popper.Content>
        <Box
          display="flex"
          flexWrap="wrap">
          {currentSentence.fragments.map((fragment, index) => {
            const { type } = fragment
            return (
              <Typography
                variant="paragraph"
                key={index}
                mr={0.5}>
                {type === 'readOnly' && <Html body={fragment.content} />}
                {type === 'select' && (
                  <select
                    disabled
                    style={{ width: '10rem' }}
                  />
                )}
                {type === 'text' && <input disabled />}
              </Typography>
            )
          })}
        </Box>
      </Popper.Content>
    </Popper>
  )
}

export default Content
