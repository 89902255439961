import DeleteIcon from '@mui/icons-material/DeleteForever'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { itemRendererProps } from 'common/formControls/lists/AdvancedList'
import RichTextEdit from 'common/formControls/textInputs/RichTextEdit'
import { set } from 'fp/objects'
import { forwardRef } from 'react'

const excludeToolButtons = ['Indentation', 'Link', 'Lists']

const PollItemRenderer = forwardRef(
  ({ allowDeletion, disabled, index, onChange, removeItem, value }, ref) => {
    const handleValueChange =
      fieldName =>
      ({ target }) =>
        onChange(set(fieldName, target.value)(value))
    const handleRemove = () => removeItem(index)

    const answerNumber = index + 1

    return (
      <>
        <Box
          component="fieldset"
          flex={1}>
          <Box
            component="legend"
            mb={2}>
            {`Answer ${answerNumber}`}
          </Box>
          <RichTextEdit
            {...{ disabled, excludeToolButtons, ref }}
            features={{ 'images.inline': true }}
            label="Text"
            minimized
            onChange={handleValueChange('label')}
            required
            value={value.label}
            variant="outlined"
          />
        </Box>
        {Boolean(allowDeletion) && (
          <IconButton
            disabled={disabled}
            onClick={handleRemove}>
            <DeleteIcon />
          </IconButton>
        )}
      </>
    )
  },
)

PollItemRenderer.propTypes = itemRendererProps

export default PollItemRenderer
