import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Well from 'apps/common/layout/Well'
import AdvancedList from 'common/formControls/lists/AdvancedList'
import Headline from 'common/text/Headline'
import { identity, takeSecond } from 'fp/utils'
import { useFormContext, useWatch } from 'react-hook-form'
import { compose } from 'redux'
import ItemRenderer from './ItemRenderer'
import PreviewContent from './PreviewContent'

interface ContentProps {
  newSentences: () => void
  newFragment: () => void
}

const Content = (props: ContentProps) => {
  const { newSentences, newFragment } = props
  const sentences = useWatch({
    name: 'sentences',
  })
  const { setValue } = useFormContext()

  return (
    <Box mt={4}>
      <Headline title="Sentences" />
      <List>
        {sentences.map((sentence: Sentence, index: number) => {
          return (
            <ListItem
              data-testid="sentence"
              key={sentence.id}
              sx={{
                padding: 0,
                justifyContent: 'space-between',
                display: 'flex',
              }}>
              <Well
                component="fieldset"
                sxProps={{ flex: 1 }}>
                <Box
                  component="legend"
                  mb={2}>
                  <Headline title={`Sentence ${index + 1}`} />
                </Box>
                <AdvancedList
                  getter={identity}
                  itemContainerProps={{
                    paddingBottom: 2,
                  }}
                  ItemRenderer={ItemRenderer}
                  minimumItems={1}
                  name={`sentences[${index}].fragments`}
                  noLabel
                  setter={takeSecond}>
                  {({ addItem = () => {}, disabled = false }) => (
                    <Box
                      display="flex"
                      justifyContent={'space-evenly'}>
                      <Button
                        data-testid="add-fragment"
                        disabled={disabled}
                        onClick={compose(addItem, newFragment)}>
                        Add Fragment
                      </Button>
                      <Button
                        data-testid="delete-sentence"
                        onClick={() => {
                          setValue(
                            'sentences',
                            sentences.filter(
                              (_sentence: Sentence, i: number) => i !== index,
                            ),
                          )
                        }}>
                        Delete Sentence
                      </Button>
                    </Box>
                  )}
                </AdvancedList>
              </Well>
              <PreviewContent currentSentence={sentence} />
            </ListItem>
          )
        })}
      </List>
      <Button
        data-testid="add-sentence"
        onClick={() => {
          setValue('sentences', [...sentences, newSentences()])
        }}>
        Add Sentence
      </Button>
    </Box>
  )
}

export default Content

export type Sentence = {
  id: string
  fragments: {
    id: string
    type: string
    content: string
  }[]
}
