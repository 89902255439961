import DeleteIcon from '@mui/icons-material/DeleteForever'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import type { SelectChangeEvent } from '@mui/material/Select'
import type { itemRendererProps } from 'common/formControls/lists/AdvancedList'
import Select from 'common/formControls/selects/Select'
import RichTextEdit from 'common/formControls/textInputs/RichTextEdit'
import TextField from 'common/formControls/textInputs/TextField'
import Warning from 'common/layout/Warning'
import Headline from 'common/text/Headline'
import { first } from 'fp/arrays'
import { get, set } from 'fp/objects'
import type {
  DropdownOption,
  DropdownOptionSet,
} from 'hss/ContentBuilder/interactives/FillInTheBlankAndCloze/DropdownOptionSets/Item'
import { produce } from 'immer'
import { type SyntheticEvent, forwardRef, useEffect, useMemo } from 'react'
import { useWatch } from 'react-hook-form'
import { compose } from 'redux'
interface ItemRendererProps {
  allowDeletion?: boolean
  disabled?: boolean
  index: number
  onChange: (value: unknown) => void
  removeItem: (index: number) => void
  value: {
    content: string
    correctAnswer: string
    optionSet: string
    type: 'readOnly' | 'text' | 'select'
  }
}

const excludeToolButtons = [
  'Format',
  'Highlight',
  'Indentation',
  'Link',
  'Lists',
]

const ItemRenderer = forwardRef(
  (props: ItemRendererProps & typeof itemRendererProps, ref) => {
    const { allowDeletion, disabled, index, onChange, removeItem, value } =
      props
    const dropdownOptionSets: DropdownOptionSet[] =
      useWatch({
        name: 'dropdownOptionSets',
      }) || []
    const fragmentNumber = index + 1
    const defaultOptionSet: DropdownOptionSet | undefined =
      first(dropdownOptionSets)

    const selectedOptionSet = useMemo(() => {
      return (
        dropdownOptionSets.find(option => option.id === value.optionSet) ||
        defaultOptionSet
      )
    }, [defaultOptionSet, dropdownOptionSets, value.optionSet])

    const handleValueChange =
      (fieldName: string) =>
      (event: SyntheticEvent | SelectChangeEvent<unknown>) => {
        const targetValue = (event.target as HTMLInputElement).value

        onChange(
          produce(draft => {
            draft[fieldName] = targetValue

            if (fieldName === 'type') {
              draft.content = ''
              draft.optionSet = ''
              draft.correctAnswer = ''

              if (
                targetValue === 'select' &&
                dropdownOptionSets.length &&
                defaultOptionSet
              ) {
                draft.optionSet = defaultOptionSet.id
              }
            }
          })(value),
        )
      }

    const handleRemove = () => removeItem(index)

    const handleSelectChange =
      (fieldName: string) =>
      ({ target }: SelectChangeEvent<unknown>) =>
        onChange(set(fieldName, target.value)(value))

    useEffect(() => {
      if (
        value.type === 'select' &&
        dropdownOptionSets.length &&
        selectedOptionSet
      ) {
        const { id = '', options = [] } = selectedOptionSet
        const isValidAnswer = options.some(
          (option: DropdownOption) => option.label === value.correctAnswer,
        )
        const newCorrectAnswer = isValidAnswer
          ? value.correctAnswer
          : get('label')(first(options)) || ''
        if (
          id !== value.optionSet ||
          newCorrectAnswer !== value.correctAnswer
        ) {
          onChange(
            compose(
              set('optionSet', id),
              set('correctAnswer', newCorrectAnswer),
            )(value),
          )
        }
      }
    }, [dropdownOptionSets, value, onChange, selectedOptionSet])

    return (
      <>
        <Box
          component="fieldset"
          flex={1}
          data-testid="fragments">
          <Box component="legend">
            <Headline title={`Fragment ${fragmentNumber}`} />
          </Box>
          <Box mb={2}>
            <Select
              label="Type"
              data-testid="fragment-type"
              name={`type-${fragmentNumber}`}
              fullWidth
              value={value.type}
              onChange={handleValueChange('type')}>
              <MenuItem value="readOnly">Read Only</MenuItem>
              <MenuItem value="text">Text</MenuItem>
              <MenuItem value="select">Select</MenuItem>
            </Select>
          </Box>
          {value.type === 'readOnly' && (
            <Box my={2}>
              <RichTextEdit
                {...{ disabled, excludeToolButtons, ref }}
                features={{ 'images.inline': true }}
                data-testid="fragment-content"
                label="Content"
                minimized
                required
                name={`content-${fragmentNumber}`}
                onChange={handleValueChange('content')}
                value={value.content}
                variant="outlined"
              />
            </Box>
          )}
          {value.type === 'select' && (
            <Box my={2}>
              {Boolean(dropdownOptionSets.length) && (
                <Select
                  label="Option Set"
                  data-testid="fragment-option-set"
                  value={value.optionSet}
                  onChange={handleSelectChange('optionSet')}
                  fullWidth
                  name={`optionSet-${fragmentNumber}`}>
                  {dropdownOptionSets.map(option => (
                    <MenuItem
                      key={option.id}
                      value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Box>
          )}
          {value.type === 'text' && (
            <Box my={2}>
              <TextField
                data-testid="fragment-correct-answer"
                label="Correct Answer"
                name={`correctAnswer-${fragmentNumber}`}
                onChange={handleValueChange('correctAnswer')}
                value={value.correctAnswer}
                variant="filled"
                required
              />
            </Box>
          )}
          {value.type === 'select' && selectedOptionSet && (
            <Box my={2}>
              {Object.keys(selectedOptionSet).length ? (
                <Select
                  data-testid="fragment-correct-answer"
                  label="Correct Answer"
                  name={`correctAnswer-${fragmentNumber}`}
                  onChange={handleValueChange('correctAnswer')}
                  value={value.correctAnswer}
                  fullWidth>
                  {selectedOptionSet.options.map((option: DropdownOption) => (
                    <MenuItem
                      key={option.id}
                      value={option.label}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <Warning
                  bgcolor="error.light"
                  variant="contained">
                  <strong>NOTICE:</strong> Need to create an option set above in
                  order to use this type.
                </Warning>
              )}
            </Box>
          )}
        </Box>
        {Boolean(allowDeletion) && (
          <IconButton
            data-testid="delete-fragment"
            disabled={disabled}
            onClick={handleRemove}>
            <DeleteIcon />
          </IconButton>
        )}
      </>
    )
  },
)

export default ItemRenderer
