import Tooltip from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import {
  ABILITY_CONTENT_CREATION,
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_SUBSECTION,
} from 'core/consts'
import { contentShape } from 'core/shapes'
import { isEmptyString } from 'fp/strings'
import withAbilityCheck from 'hoc/withAbilityCheck'
import withProps from 'hoc/withProps'
import { Eye as Icon } from 'react-feather'
import { contentViewerUrl } from 'routing/consts'
import { containedPaddingRight } from 'styling/theming/base'

const NewTabLink = withProps('a', { rel: 'opener', target: '_blank' })

const getParentIdByType = (content, contentType) => {
  let cursor = content
  while (cursor?.parent) {
    if (cursor.contentType === contentType) return cursor.id
    cursor = cursor.parent
  }

  return 0
}

const Positioned = styled(withAbilityCheck('div', ABILITY_CONTENT_CREATION), {
  name: 'Block-LinkToViewer',
})(
  ({
    theme: {
      mixins: { important },
      palette,
      zIndex,
    },
  }) => ({
    position: 'absolute',
    right: containedPaddingRight,
    backgroundColor: palette.background.default,
    a: {
      color: important(palette.error.main),
      lineHeight: 1,
    },
    zIndex: zIndex.fab,
  }),
)

const Link = props => (
  <Positioned>
    <NewTabLink {...props}>
      <Tooltip title="Open content viewer in new tab">
        <Icon />
      </Tooltip>
    </NewTabLink>
  </Positioned>
)

const LinkToViewer = ({ content }) => {
  const chapterId = getParentIdByType(content, CONTENT_TYPE_CHAPTER)
  const echoId = getParentIdByType(content, CONTENT_TYPE_ECHO)
  const subsectionId = getParentIdByType(content, CONTENT_TYPE_SUBSECTION)

  let url

  if (chapterId) {
    url = `${contentViewerUrl}/${CONTENT_TYPE_CHAPTER}/${chapterId}`
  }

  if (content?.contentType === CONTENT_TYPE_ECHO && !echoId) {
    url = `${contentViewerUrl}/${CONTENT_TYPE_ECHO}/${content.id}`
  }

  if (chapterId && echoId) {
    url = `${contentViewerUrl}/${CONTENT_TYPE_CHAPTER}/${chapterId}/${CONTENT_TYPE_ECHO}/${echoId}`
  }

  if (chapterId && subsectionId) {
    url = `${contentViewerUrl}/${CONTENT_TYPE_CHAPTER}/${chapterId}/${CONTENT_TYPE_SUBSECTION}/${subsectionId}`
  }

  if (isEmptyString(url)) return null

  return <Link href={url} />
}

LinkToViewer.propTypes = { content: contentShape.isRequired }

export default LinkToViewer
