import { maxFormWidth } from 'common/formControls/Form/utils'
import HFRichTextEdit from 'common/formControls/textInputs/HFRichTextEdit'
import { INTERACTIVE_TYPE_IMAGE } from 'core/consts'
import withProps from 'hoc/withProps'

const QuestionPrompt = withProps(HFRichTextEdit, {
  allowedInteractives: [INTERACTIVE_TYPE_IMAGE],
  features: { interactives: true, images: true },
  label: 'Question / Prompt',
  name: 'questionHtml',
  required: true,
})

export const QuestionPromptWithHeadline = withProps(QuestionPrompt, {
  labelProps: {
    headline: true,
    mb: 2,
    mt: 3,
  },
  maxWidth: maxFormWidth,
})

export default QuestionPrompt
