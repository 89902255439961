import WordBank from 'common/formControls/selects/WordBank'
import RichTextEdit from 'common/formControls/textInputs/HFRichTextEdit'
import { getMaxHeight } from 'common/formControls/textInputs/RichTextEdit/utils/misc'
import CollapsibleSection from 'common/layout/CollapsibleSection'
import { CONTENT_TYPE_INTERACTIVE, INTERACTIVE_TYPE_ESSAY } from 'core/consts'
import { generateId } from 'fp/utils'
import withContent from 'hoc/withContent'
import EssayInteractive from 'hss/sections/contentBlocks/interactives/Essay'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'
import QuestionPrompt from '../Form/QuestionPrompt'
import Content from './Content'

const contentSubType = INTERACTIVE_TYPE_ESSAY
const Interactive = EssayInteractive

const newWordSet = () => ({
  id: generateId(),
  primaryWord: '',
  variations: [],
})

const defaultValues = {
  prompt: '',
  sampleAnswer: null,
  rteSettings: {
    toolButtonOptions: {
      all: false,
      none: false,
      bold: true,
      italic: true,
      underline: true,
      strikethrough: false,
      highlight: false,
      superscript: false,
      subscript: false,
      format: false,
      lists: true,
      link: false,
      indentation: false,
    },
    numberOfLines: 7,
  },
  contentWordBank: [],
}

const previewButton = props => <PreviewButton {...{ Interactive, ...props }} />

const Essay = formProps => (
  <Form
    {...{ contentSubType, defaultValues, formProps, previewButton }}
    name="Interactive-Essay"
    standardsAllowed>
    <QuestionPrompt
      labelProps={{
        headline: true,
        mb: 2,
        mt: 3,
      }}
      name="prompt"
    />

    <RichTextEdit
      labelProps={{
        headline: true,
        mb: 2,
        mt: 3,
      }}
      maxHeight={getMaxHeight()}
      mb={5}
      name="sampleAnswer"
    />

    <CollapsibleSection
      initiallyExpanded
      label="Word Bank">
      <WordBank
        name="contentWordBank"
        newWordSet={newWordSet}
      />
    </CollapsibleSection>

    <Content />
  </Form>
)

const EssayWithContent = withContent(Essay, {
  contentType: CONTENT_TYPE_INTERACTIVE,
})

export default EssayWithContent
