import HFAssetUploader from 'common/formControls/selects/AssetUploader/HFAssetUploader'
import {
  CONTENT_TYPE_INTERACTIVE,
  INTERACTIVE_TYPE_DOCUMENT,
} from 'core/consts'
import withContent from 'hoc/withContent'
import ImageInteractive from 'hss/sections/contentBlocks/interactives/Image'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'

const contentSubType = INTERACTIVE_TYPE_DOCUMENT
const Interactive = ImageInteractive
const defaultValues = {
  uploadsMap: {},
}

const additionalInteractiveProps = {
  contentId: 'previewing',
}

const previewButton = props => (
  <PreviewButton {...{ additionalInteractiveProps, Interactive, ...props }} />
)

const Document = formProps => {
  return (
    <Form
      {...{
        contentSubType,
        defaultValues,
        formProps,
        previewButton,
      }}
      floatSupported
      interactiveInstructionsAllowed={false}
      name="Interactive-Document"
      studentInstructionsAllowed={false}>
      <HFAssetUploader
        label="Document"
        name="uploadsMap.document"
        uploadType="document"
      />
    </Form>
  )
}

const DocumentWithContent = withContent(Document, {
  contentType: CONTENT_TYPE_INTERACTIVE,
})

export default DocumentWithContent
